import { HttpClientModule, HttpClient } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatNativeDateModule } from '@angular/material/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { ToastrModule } from 'ngx-toastr';
import { MultiTranslateHttpLoader } from 'ngx-translate-multi-http-loader';
import { IsLoginModule } from 'projects/is-ui-kit/src/lib/components/is-login/is-login.module';
import { toastrConfig } from './utils/configs';
import { ActionToastComponent } from './utils/custom-notifiers/action-toast/action-toast.component';
import { MatIconModule } from '@angular/material/icon';

export const customNotifiers = [ActionToastComponent];

export function _translateHttpLoaderFactory(
  http: HttpClient
): MultiTranslateHttpLoader {
  return new MultiTranslateHttpLoader(http, [
    { prefix: 'assets/i18n/', suffix: '.json' },
    { prefix: 'assets/_i18n/', suffix: '.json' }
  ]);
}

@NgModule({
    declarations: customNotifiers,
    imports: [
        BrowserModule,
        HttpClientModule,
        BrowserAnimationsModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: _translateHttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        ToastrModule.forRoot(toastrConfig),
        IsLoginModule,
        /** important! you never should import again MatNativeDateModule or you will ovveride this */
        MatNativeDateModule,
        /** importing MatButtonModule because its used in the custom toastr */
        MatButtonModule,
        MatIconModule
    ]
})
export class UtilsInitializerModule {}
