import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SettingsService } from '../settings/settings.service';

@Injectable({
  providedIn: 'root'
})

export class IsApiService {
  constructor(public settings: SettingsService, private _http: HttpClient) { }

  /**
   * Method for perform an http request.
   * @param options object containing the params needed for the request.
   * @returns returns an http observable.
   * @example //How to use it:
   * const request = this._isApi.request({
   *  api: 'getAssets',
   *  method: 'get',
   *  queryParams: '?site=1'
   * });
   */
  public request(options: {
    api: string;
    method: 'get' | 'post' | 'patch' | 'put' | 'delete' | 'blob' | 'open' | 'options';
    queryParams?: string;
    bodyRequest?: any;
    params?: {
      [key: string]: any;
    };
  }): Observable<any> {
    if (!this.settings.getApi(options?.api)) {
      return;
    }
    const queryParams = options?.queryParams ?? '';
    const bodyRequest = options?.bodyRequest ?? {};
    const url = this.settings.formatUrl({
      url: this.settings.getApi(options?.api),
      queryParams,
      params: options.params
    });

    switch (options?.method.toLowerCase()) {
      case 'get': {
        return this._http.get<any>(url);
      }
      case 'post': {
        return this._http.post<any>(url, bodyRequest);
      }
      case 'patch': {
        return this._http.patch<any>(url, bodyRequest);
      }
      case 'put': {
        return this._http.put<any>(url, bodyRequest);
      }
      case 'delete': {
        return this._http.delete<any>(url, bodyRequest);
      }
      case 'blob': {
        return this._http.get(url, { responseType: 'blob' });
      }
      case 'options': {
        return this._http.options<any>(url);
      }
      case 'open': {
        window.open(url);
      }
    }
  }

  /**
   * this function is only a wrapper of the observable and returns
   * his data on a callbackfn. Isteand is better work with a subscribe just on
   * the original observable
   */
  public subscribe(subscribeTo: Observable<any>, callbackFn?: (error: Error, data: any) => void, onComplete?: () => void): void {
    if (subscribeTo) {
      subscribeTo.subscribe({
        next: (data) => {
          if (callbackFn) {
            callbackFn(null, data);
          }
        },
        error: (err: Error) => {
          if (callbackFn) {
            callbackFn(err, null);
          }
        },
        complete: () => {
          if (onComplete) {
            onComplete();
          }
        }
      });
    }
  }
}
