import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { IS_APP_INITIALIZER } from 'projects/is-core/src/lib/app-initializer/app-initializer.service';
import { IS_INTERCEPTOR } from 'projects/is-core/src/lib/interceptor/interceptor.service';
import { UtilsInitializerModule } from 'projects/is-core/src/lib/utils-initializer/utils-initializer.module';
import { InterceptorModule } from 'projects/is-core/src/lib/interceptor/interceptor.module';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    UtilsInitializerModule,
    InterceptorModule
  ],
  providers: [
    IS_APP_INITIALIZER,
    IS_INTERCEPTOR
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
