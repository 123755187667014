import { HttpClient } from '@angular/common/http';
import { APP_INITIALIZER, Injectable } from '@angular/core';
import { SettingsService } from '../settings/settings.service';
import { Subscription } from 'rxjs';
import { UtilsInitializerService } from '../utils-initializer/utils-initializer.service';

@Injectable({
  providedIn: 'root'
})
export class AppInitializerService {
  public initialized: boolean = false;

  constructor(
    private _http: HttpClient,
    private _settingsService: SettingsService,
    private _utilsInitiliazier: UtilsInitializerService
  ) { }

  /**
   * METHODS THAT INIZIALIZE ALL APPLICATION
   * HERE ARE CALLED METHODS FOR:
   * SETTING.JSON
   * LOCAL_SETTINGS.JSON
   * API.JSON
   * ICONS
   */
  public Init(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this._loadSettingsJson(() => {
        this._loadApiJson(() => {
          this._loadPathsJson(() => {
            resolve();
          }, reject);
        }, reject);
      }, reject);
    }).then(async () => {
      await this._utilsInitiliazier.init();
    });
  }

  /**
   * METHOD FOR LOAD FILE SETTINGS.JSON
   * @param then callback function
   * @param reject m
   */
  private _loadSettingsJson(then: () => void, reject: () => void): void {
    const sub: Subscription = this._http
      .get('assets/configuration-files/settings.json')
      .subscribe({
        next: (data: any) => {
          this._settingsService.setSettings(data);
        },
        error: (err: Error) => {
          console.error(err.message);
          reject();
          setTimeout(() => sub.unsubscribe());
        },
        complete: () => {
          then();
          setTimeout(() => sub.unsubscribe());
        }
      });
  }

  /**
   * METHOD FOR LOAD FILE API.JSON
   * @param then callback function
   * @param reject m
   */
  private _loadApiJson(then: () => void, reject: () => void): void {
    const sub: Subscription = this._http
      .get('assets/configuration-files/api.json')
      .subscribe({
        next: (data: any) => {
          this._settingsService.setApis(data);
        },
        error: (err: Error) => {
          console.error(err.message);
          reject();
          setTimeout(() => sub.unsubscribe());
        },
        complete: () => {
          then();
          setTimeout(() => sub.unsubscribe());
        }
      });
  }

  /**
   * METHOD FOR LOAD ALL ICONS IN FOLDER IS_ICONS
   * @param then callback function
   * @param reject m
   */
  private _loadPathsJson(then: () => void, reject: () => void): void {
    const sub: Subscription = this._http
      .get('assets/configuration-files/paths.json')
      .subscribe({
        next: (data: any) => {
          this._settingsService.setPaths(data);
        },
        error: (err: Error) => {
          console.error(err.message);
          reject();
          setTimeout(() => sub.unsubscribe());
        },
        complete: () => {
          then();
          setTimeout(() => sub.unsubscribe());
        }
      });
  }
}

/**
 * FUNCTION THAT CALL METHOD FOR INITIALIZE APPLICATION
 * @param appInitializerService service
 * @returns  return the app
 */
function _initialize(
  appInitializerService: AppInitializerService
): () => Promise<any> {
  return (): Promise<any> => {
    return appInitializerService.Init();
  };
}

export const IS_APP_INITIALIZER = {
  provide: APP_INITIALIZER,
  useFactory: _initialize,
  multi: true,
  deps: [AppInitializerService]
};
