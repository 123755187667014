import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { CookieService } from 'ngx-cookie';
import { SettingsConstants } from 'projects/is-core/src/lib/settings/settings.service';

@Injectable({
  providedIn: 'root'
})
export class LoginAuthGuard implements CanActivate {

  constructor(private _cookieService: CookieService) { }

  canActivate(): boolean {
    if (!this._cookieService.get('token')) {
      // if login url is present has key in settings.json redirect to specified url - else redirect to default '/login' path
      if (SettingsConstants.AVOIDLOGIN) {
        location.href = `${SettingsConstants.BACKENDURL}${SettingsConstants.LOGINURL}`;
        return false;
      } else {
        return true;
      }
    }
    return true;
  }

}
