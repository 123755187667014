// import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthguardGuard } from 'projects/is-ui-kit/src/lib/components/is-login/auth-guard.guard';
import { IsLoginComponent } from 'projects/is-ui-kit/src/lib/components/is-login/is-login.component';
import { LoginAuthGuard } from '../../../is-core/src/lib/login-auth.guard';

const routes: Routes = [
  { path: 'login', component: IsLoginComponent, canActivate: [LoginAuthGuard] },
  { path: '', loadChildren: () => import('./modules/modules.module').then((m) => m.ModulesModule), canActivate: [AuthguardGuard] }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  // providers: [{ provide: LocationStrategy, useClass: HashLocationStrategy }],
  exports: [RouterModule]
})
export class AppRoutingModule { }
