import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, CanActivate, Router } from '@angular/router';
import { IsLoginService } from './is-login.service';

@Injectable({
  providedIn: 'root'
})
export class AuthguardGuard implements CanActivate {
  constructor(
    private _router: Router,
    private _isLoginSerivce: IsLoginService
  ) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    const routeurl: string = state?.url;
    const qParams = route.queryParams;
    return this.isLogin(routeurl, qParams);
  }

  private isLogin(routeurl: string, qParams: any): boolean {
    if (this._isLoginSerivce.isLoggedIn()) {
      if (qParams.token !== undefined) {
        this.manageExtParams(qParams);
        const newrouterurl: string = routeurl.split('?')[0];
        this._router.navigate([newrouterurl], { queryParams: {} });
      }
      return true;
    } else if (qParams.token !== undefined) { // autologin from external source ?
      this.manageExtParams(qParams);
      const newrouterurl: string = routeurl.split('?')[0];
      this._router.navigate([newrouterurl], { queryParams: {} });
      return true;
    } else {
      this._isLoginSerivce.redirectUrl = routeurl;
      this._router.navigate(['/login'], {
        queryParams: { returnUrl: routeurl }
      });
      return false;
    }
  }

  private manageExtParams(qParams: any) {
    this._isLoginSerivce.setToken(qParams.token);
    if (qParams.period !== undefined) {
      this._isLoginSerivce.setCookie('period', qParams.period);
    }
    if (qParams.time_from !== undefined) {
      this._isLoginSerivce.setCookie('time_from', qParams.time_from);
    }
    if (qParams.time_to !== undefined) {
      this._isLoginSerivce.setCookie('time_to', qParams.time_to);
    }
  }
}
