import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse, HTTP_INTERCEPTORS } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie';
import { ToastrService } from 'ngx-toastr';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { SettingsConstants } from '../settings/settings.service';

@Injectable({
  providedIn: 'root'
})
export class InterceptorService implements HttpInterceptor {
  constructor(
    private _router: Router,
    private _toastr: ToastrService,
    private _translate: TranslateService,
    private _cookie: CookieService
  ) { }

  /** inject the token in all the http request and hadle any error */
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const regex = /\b(assets|configuration-files)\b.*\.(json|svg)\b$/;
    const isAssetFile = regex.test(request.url);

    /** if token is found injecting it into the request */
    if (!isAssetFile) {
      if (this._cookie.get('token')) {
        request = request.clone({
          setHeaders: { Authorization: `token ${this._cookie.get('token')}` }
        });
      }
    }

    /** returing the request and handling errors */
    return next.handle(request).pipe(
      catchError((err: any) => {
        if (err instanceof HttpErrorResponse) {
          this._notifyError(err);
        }
        return throwError(err);
      })
    );
  }

  private _notifyError(httpError: HttpErrorResponse): void {

    /** for errors between 400 and 499 */
    if (httpError.status < 500) {
      if (this._bypassInterceptor(httpError)) {
        return;
      }
      /** printing the error in the console */
      console.error(httpError.error);
      switch (httpError.status) {
        case 401: {
          this._toastr.error('error', this._translate.instant('ERRORS.401'));
          this._cookie.remove('token', { path: '/' });
          if (location.hostname === 'localhost' || location.hostname === '127.0.0.1') {
            this._router.navigate(['login']);
          } else {
            const url: string = `${SettingsConstants.BACKENDURL}${SettingsConstants.LOGINURL ?
              SettingsConstants.LOGINURL : '/'}`;
            window.open(url, '_self');
          }
          break;
        }
        case 403: {
          this._toastr.error(this._translate.instant('ERRORS.403'));
          break;
        }
        case 404: {
          if (!httpError?.url.includes('/dataxp/v2/data_experience_all/')) {
            this._toastr.error(this._translate.instant('ERRORS.404'));
          }
          break;
        }
        default: {
          if (httpError?.error?.errors) {
            if (Array.isArray(httpError?.error?.errors)) {
              this._toastr.error(httpError?.error?.errors);
            } else {
              Object.keys(httpError.error.errors).map((message) => {
                this._toastr.error(message);
              });
            }
          }
          if (httpError?.error?.warnings) {
            Object.keys(httpError.error.warnings).map((message) => {
              this._toastr.warning(message);
            });
          }
          break;
        }
      }
    } else {
      switch (httpError.status) {
        case 503: {
          this._toastr.error(this._translate.instant('ERRORS.503'));
          break;
        }
        /** errors in 500 arent managed from the server so there isnt intention to print it */
        default: {
          this._toastr.error(this._translate.instant('ERRORS.generic'));
          break;
        }
      }
    }
  }


  /**
   * method for bypassing the interceptor.
   * @param httpError the response of the http request
   * @returns true if we don't use the interceptor, else false
   */
  private _bypassInterceptor(httpError: HttpErrorResponse): boolean {
    if (httpError.url.includes('/assets/configuration-files/local_settings.json')) {
      return true;
    }
    return false;
  }
}

export const IS_INTERCEPTOR = {
  provide: HTTP_INTERCEPTORS,
  useClass: InterceptorService,
  multi: true
};
