<div class="at-flex" [style.display]="state.value === 'inactive' ? 'none' : ''">
  <div class="">
    <div *ngIf="title" [class]="options.titleClass" [attr.aria-label]="title">
      {{ title }}
    </div>
    <div *ngIf="message && options.enableHtml" role="alert" aria-live="polite" [class]="options.messageClass"
      [innerHTML]="message"></div>
    <div *ngIf="message && !options.enableHtml" role="alert" aria-live="polite" [class]="options.messageClass"
      [attr.aria-label]="message">
      {{ message }}
    </div>
  </div>

  <ng-container *ngIf="buttonText">
    <button mat-button (click)="action($event)" class="toast-action-button">
      <span>{{ buttonText | uppercase }}</span>
    </button>
  </ng-container>

  <ng-container *ngIf="options?.closeButton">
    <div class="at-close-button">
      <button (click)="remove()" aria-label="Close" class="toast-close-button">
        <!-- <span aria-hidden="true" style="margin-left:20px">x</span> -->
        <mat-icon svgIcon="close" class="icon" style="margin-left:20px; margin-top: 7px;"></mat-icon>
      </button>
    </div>

  </ng-container>

</div>
<div *ngIf="options.progressBar">
  <div class="toast-progress" [style.width]="width + '%'"></div>
</div>