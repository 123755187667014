import { Injectable } from '@angular/core';
import { getNestedKey } from '../is-functions';

/**
 * Class where are declared all common
 * constants about setting, for example will be declared
 * all settings from "settings.json" file but also other settings such as
 * "LOGGEDUSER" and others ...
 */
export class SettingsConstants {

  public static BACKENDURL; // URL of the server to point to for api calls
  public static CUSTOMSETTINGS; // custom options
  public static LANG; // the language of the application
  public static LOGINURL; // login page url, if not setted redirect to "/login"
  public static AVOIDLOGIN; // login page url, if not setted redirect to "/login"
  public static VERSION; // version of the project, example "20210728"
  public static PROJECTTITLE; // title of the project, example "CMMS"
  public static LOGGEDUSER; // the logged user information
  public static USERPREFERENCES; // the user preferences information
  public static USERPERMISSIONS: any = {}; // the user permissions
  public static USERCUSTOMIZATIONS: any = {}; // the customizations
  public static DECIMALCONFIG: any = null;
  public static DATAXPREFRESH: any;
  public static MAPLICENSE;
  public static DEVICE: string;
  public static CONTEXTPATH: string;

  public static get HOST_NAME(): string {
    // returns a string with "BACKENDURL" property
    return SettingsConstants.BACKENDURL ? SettingsConstants.BACKENDURL : '';
  }
}

@Injectable({
  providedIn: 'root'
})

export class SettingsService {
  private _settings: object;
  public _apis: any;
  private _paths: object;

  /**
   * method that assign the given "setting object" to
   * the private _settings property of the class.
   * Also assign all the "SettingsConstants" properties.
   * @param settings an object that define some settings field
   * (probably it will contain the setting.json content).
   */
  public setSettings(settings: any): void {
    // merge the previous setting object the object paased as param,
    // useful when local_settings object has been passed
    this._settings = { ...this._settings, ...settings };

    if (settings?.backend_url) {
      SettingsConstants.BACKENDURL = settings.backend_url;
    } else {
      SettingsConstants.BACKENDURL = location.origin;
    }

    if (settings?.custom_settings) {
      SettingsConstants.CUSTOMSETTINGS = settings.custom_settings;
    }
    if (settings?.lang) {
      SettingsConstants.LANG = settings.lang;
    }
    if (settings?.login_url) {
      SettingsConstants.LOGINURL = settings.login_url;
    }
    if (settings?.version) {
      SettingsConstants.VERSION = settings.version;
    }
    if (settings?.project_title) {
      SettingsConstants.PROJECTTITLE = settings.project_title;
    }
    if (settings?.decimal) {
      SettingsConstants.DECIMALCONFIG = settings.decimal;
    }
    if (settings?.refresh_time) {
      SettingsConstants.DATAXPREFRESH = settings.refresh_time;
    }

    if (settings?.avoid_login) {
      SettingsConstants.AVOIDLOGIN = settings.avoid_login;
    } else {
      SettingsConstants.AVOIDLOGIN = false;
    }

    if (settings?.map_license) {
      SettingsConstants.MAPLICENSE = settings.map_license;
    } else {
      SettingsConstants.MAPLICENSE = undefined;
    }

    if (this.checkUserDevice()) {
      SettingsConstants.DEVICE = this.checkUserDevice();
    } else {
      SettingsConstants.DEVICE = 'desktop';
    }

    if (settings?.context_path) {
      SettingsConstants.CONTEXTPATH = settings.context_path;
    } else {
      SettingsConstants.CONTEXTPATH = null;
    }
  }

  public checkUserDevice(): string {
    const userAgent = navigator.userAgent || navigator.vendor || (window as any).opera;
    if (this.isAndroidPhone(userAgent) || this.isIPhone(userAgent)) {
      return 'mobile';
    } else if (this.isTablet(userAgent) || this.isIPad(userAgent)) {
      return 'tablet';
    } else {
      return 'desktop';
    }
  }

  private isAndroidPhone(userAgent): boolean {
    return /android/i.test(userAgent) && !this.isTablet(userAgent);
  }

  private isIPhone(userAgent): boolean {
    return /iPhone/.test(userAgent) && !this.isIPad(userAgent);
  }

  private isTablet(userAgent): boolean {
    return /iPad|android(?!.*mobile)/i.test(userAgent);
  }

  private isIPad(userAgent): boolean {
    return /iPad/.test(userAgent);
  }

  /**
   * Return the settings json as object.
   * @returns the settings as object.
   */
  public getSettings(): object {
    return this._settings;
  }

  /**
   * Return a specified value of a key from the settings json.
   * @param key the key to read from the settings json.
   * @returns the value of the key.
   */
  public getSetting(key?: string): any {
    const setting = getNestedKey(this._settings, key);
    if (setting === undefined) {
      console.error(`The setting: ${key} is missing in the settings.json!`);
    }
    return setting;
  }

  public setApis(apis: object): void {
    this._apis = apis;
  }

  public getApis(): object {
    return this._apis;
  }

  public getApi(key?: string): string {
    const api = getNestedKey(this._apis, key);
    if (api === undefined) {
      console.error(`The api: ${key} is missing in the api.json!`);
    }
    return api;
  }

  public setPaths(paths: object): void {
    this._paths = paths;
  }

  public getPath(key?: string): string {
    const path = getNestedKey(this._paths, key);
    if (path === undefined) {
      console.error(`The path: ${key} is missing in the paths.json!`);
    }
    return path;
  }

  public getBaseHref(path?: string): string {
    // eslint-disable-next-line no-useless-escape
    const contextPathRegex = /^\/[a-zA-Z0-9]+[^\/]$/;
    const tempPath = getNestedKey(this._paths, path);
    const contextPath: string = SettingsConstants?.CONTEXTPATH && contextPathRegex.test(SettingsConstants?.CONTEXTPATH) ?
      SettingsConstants.CONTEXTPATH : '';
    if (tempPath === undefined) {
      return `${contextPath}/${path}/`;
    }
    return `${contextPath}${tempPath}`;
  }

  /**
   * Format a given url adding the host name and replacing params.
   * @param options the data of the url to format.
   * @returns the formatted url.
   */
  public formatUrl(options: UrlFormatterOptions): string {
    // formatting the url with the host name, given url and query params
    let url = `${this.HOST_NAME}${options.url}${options.queryParams || ''}`;

    // replacing the keys given in the params in the url
    if (options?.params) {
      for (const key of Object.keys(options?.params)) {
        url = url.replace(`:${key}`, String(options?.params[key]));
      }
    }

    // returning the url
    return url;
  }

  // TODO: rimuovere in favore della get dentro alla classe di costanti
  get HOST_NAME(): string {
    return this.getSetting('backend_url');
  }
}

export interface UrlFormatterOptions {
  url: string;
  queryParams?: string;
  params?: {
    [key: string]: any;
  };
}
