<div class="login-page-container">


  <div class="login-container" id=login-container>

    <!-- svg logo -->
    <div class="logo">
      <img src="assets/icons/is_icons/rebecca_logo.svg">
    </div>


    <!-- motto (subtitle) section -->
    <div class="motto is-h6">
      {{ 'motto' | translate }}
    </div>

    <form class="login-form-container" [formGroup]="loginForm">
      <mat-form-field appearance="outline" class="mb--20">
        <input matInput type="text" class="is-h6" placeholder="Username" formControlName="username" autocomplete="off">
      </mat-form-field>
      <mat-form-field appearance="outline">
        <input matInput [type]="showPassword ? 'text' : 'password'" class="is-h6" placeholder="Password"
          formControlName="password" autocomplete="off">
        <mat-icon *ngIf="typingPassword" matSuffix style="cursor: pointer" [svgIcon]="showPassword ? 'hide' :'show'"
          color="primary" (click)="changeShowPassword()" [ngClass]="showPassword ? 'show-password' : null"
          matTooltip="{{ (showPassword ? 'hide_password' : 'show_password') | translate }}">
        </mat-icon>
      </mat-form-field>
      <button mat-flat-button type="submit" color="primary" class="clickable is-button" (click)="submit()"
        [disabled]="showSpinner || !loginForm?.valid">
        <span *ngIf="!showSpinner">LOGIN</span>
        <ng-container *ngIf="showSpinner">
          <div *ngIf="showSpinner" class="spinner-login">
            <mat-spinner [diameter]="30"></mat-spinner>
          </div>
        </ng-container>
      </button>
      <button mat-flat-button color="primary" class="clickable is-button" (click)="navigateExternalAuth()"
        *ngIf="infoAuth.enabled">
        {{ infoAuth.button_text | uppercase }}
      </button>
    </form>
  </div>

</div>