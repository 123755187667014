import { Injectable } from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { CookieService } from 'ngx-cookie';
import { UsersApiService } from '../is-api/api/users-api/users-api.service';
import { SettingsConstants } from '../settings/settings.service';
import { APP_ICONS, APP_ILLUSTRATIONS } from './utils/configs';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { catchError, interval, Subscription, throwError } from 'rxjs';

@Injectable({ providedIn: 'root' })

export class UtilsInitializerService {

  public intervalToken: any = interval(1000);
  public refreshTokenSub$: Subscription;
  private _intervalID: any;

  constructor(private _matIconRegistry: MatIconRegistry,
    private _domSanitizer: DomSanitizer,
    private _translateService: TranslateService,
    private _dateAdapter: DateAdapter<any>,
    private _usersService: UsersApiService,
    private _cookie: CookieService,
    private _http: HttpClient) { }

  /**
   * Setup the structure of the application.
   * @param options optional options for the setup.
   */
  public async init(): Promise<any> {
    // this._initalizeDateLocale();
    this._initializeIcons();
    await this._getLoggedUser();
    await this._getUserPreferences();
    await this._getUserPermissions();
    await this._getUserCustomizations();
    this._setLoggedUserAplicationLanguageAndDate();

    const tempCustomization: any = SettingsConstants.USERCUSTOMIZATIONS;
    if (tempCustomization?.REFRESH_TOKEN) {

      if (!localStorage.getItem('sharedTimerState')) {
        localStorage.setItem('sharedTimerState', JSON.stringify(new Date().getTime()));
      }

      this._intervalID = setInterval(() => {
        const tempRefreshInterval: any = (tempCustomization?.REFRESH_TOKEN_INTERVAL ?
          tempCustomization?.REFRESH_TOKEN_INTERVAL : 3000) * 1000;
        const tempTimer: any = localStorage.getItem('sharedTimerState') ?
          JSON.parse(localStorage.getItem('sharedTimerState')) : undefined;

        if ((new Date().getTime() - tempTimer) >= tempRefreshInterval) {
          this.refreshAccessToken();
          // localStorage.setItem('sharedTimerState', JSON.stringify(new Date().getTime()));
        }

      }, 5000);
    } else {
      localStorage.removeItem('sharedTimerState');
      clearInterval(this._intervalID);
    }
  }

  public refreshAccessToken(): void {
    const tempCustomization: any = SettingsConstants.USERCUSTOMIZATIONS;
    const tokenData = 'grant_type=refresh_token&token_type_hint=access_token';
    // skip the refresh token call if url is not defined (local envs)
    if (!tempCustomization?.API_REFRESH_URL || tempCustomization?.API_REFRESH_URL === '') {
      return;
    } else {
      this._http.post(tempCustomization?.API_REFRESH_URL, tokenData, {
        headers: new HttpHeaders({ // Imposta le intestazioni della richiesta
          'Content-Type': 'application/x-www-form-urlencoded' // Content-Type specificato
        }), withCredentials: true
      }).pipe(
        catchError((error: HttpErrorResponse) => {
          if (error.status === 400 || error.status === 401 || error.status === 403) {
            localStorage.removeItem('sharedTimerState');
            location.reload(); // Ricarica la pagina in caso di errori specifici
          } else {
            console.error('An error has occurred while refreshing token: ', error);
          }
          return throwError(() => new Error('Error refreshing token.'));
        })
      ).subscribe({
        next: (response) => {
          // eslint-disable-next-line no-unused-vars
          const a = 1; // Esegui altre operazioni necessarie
          localStorage.setItem('sharedTimerState', JSON.stringify(new Date().getTime()));
        },
        error: (error) => console.error('An error has occurred while refreshing token: ', error)
      });
    }
  }

  /**
   * TODO: (Ali) capire se tenere la funzione ora che la chiamata all'utente loggato è sincrona
   */
  // private _initalizeDateLocale(): void {
  //   const browserLang = this._translateService.getBrowserLang();
  //   this._translateService.use(browserLang);
  //   moment.locale(browserLang);
  // }

  private _initializeIcons(): void {
    const _path = 'assets/icons/is_icons/';
    const _pathIllustrations = 'assets/icons/is_illustrations/';
    const appIcons: string[] = APP_ICONS;
    const appIllustrations: string[] = APP_ILLUSTRATIONS;
    this._execLoader(appIcons, _path, appIllustrations, _pathIllustrations);
  }

  private async _getLoggedUser(): Promise<any> {
    // TODO: capire se si può rimuove async e await e dal metodo init
    // check if the user is logged
    if (this._cookie.get('token')) {
      await this._usersService.getLoggedUser(() => { });
    }
  }

  private async _getUserPreferences(): Promise<any> {
    // TODO: capire se si può rimuove async e await e dal metodo init
    // check if the user is logged
    if (this._cookie.get('token')) {
      await this._usersService.getUserPreferences();
    }
  }

  private async _getUserPermissions(): Promise<any> {
    // TODO: capire se si può rimuove async e await e dal metodo init
    // check if the user is logged
    if (this._cookie.get('token')) {
      await this._usersService.getUserPermissions();
    }
  }

  private async _getUserCustomizations(): Promise<any> {
    // TODO: capire se si può rimuove async e await e dal metodo init
    // check if the user is logged
    if (this._cookie.get('token')) {
      await this._usersService.getUserCustomizations();
    }
  }

  /**
   * method that set the language and the date format based on a series of checks
   */
  private _setLoggedUserAplicationLanguageAndDate(): void {
    let langToSet = '';
    // getting the language from settings.json
    const langFromSettings = SettingsConstants.LANG;
    // getting the language of the browser
    const langFromBrowser = this._translateService.getBrowserLang();
    // TODO: capire se la lingua del browser ha la priorità sulla lingua del settings.json
    if (langFromBrowser) {
      langToSet = langFromBrowser;
    } else if (langFromSettings) {
      langToSet = langFromSettings;
    } else {
      langToSet = 'en';
    }
    const LangFromUserApi = SettingsConstants.LOGGEDUSER?.language;
    // TODO: capire se si può rimuove async e await e dal metodo init
    // check if the user is logged
    if (this._cookie.get('token') && LangFromUserApi) {
      langToSet = LangFromUserApi;
      this._translateService.use(langToSet);
      moment.locale(langToSet);
      this._dateAdapter.setLocale(langToSet);
    } else {
      this._translateService.use(langToSet);
      moment.locale(langToSet);
      this._dateAdapter.setLocale(langToSet);
    }
  }

  private _execLoader(icons: string[], path: string, illustrations: string[], pathIllustrations: string): void {
    icons.forEach((icon) => {
      this._matIconRegistry.addSvgIcon(icon, this._domSanitizer.bypassSecurityTrustResourceUrl(path + icon + '.svg'));
    });
    illustrations.forEach((illustration) => {
      this._matIconRegistry.addSvgIcon(illustration, this._domSanitizer.bypassSecurityTrustResourceUrl(pathIllustrations + illustration + '.svg'));
    });
  }
}
