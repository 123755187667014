import * as _ from 'lodash';
import * as moment from 'moment';

// export function generateDateInterval(startDate: any, endDate: any, period?) {
//     const start =moment(startDate);
//     const end = moment(endDate);
//     const arr = new Array();
//     const dt = moment(start);

//     switch (period) {
//         case'weekly':
//         for (let i = 0; dt <= end; i++) {
//             arr.push(new Date(dt));
//             dt.setDate(dt.getDate() + 7);
//         }
//         return arr;
//         case'15min':
//          for (let i = 0; dt <= end; i++) {
//             arr.push(new Date(dt));
//             dt.setMinutes(dt.getMinutes() + 15);
//         }
//         return arr;
//         case'yearly':
//         for (let i = 0; dt <= end; i++) {
//             arr.push(new Date(dt));
//             dt.setFullYear(dt.getFullYear() + 1);
//         }
//         return arr;
//         case'monthly':
//         for (let i = 0; dt <= end; i++) {
//             arr.push(new Date(dt));
//             dt.setMonth(dt.getMonth() + 1);
//         }
//         return arr;
//         case 'daily':
//             for (let i = 0; dt <= end; i++) {
//                 arr.push(new Date(dt));
//                 dt.setDate(dt.getDate() + 1);
//             }
//             return arr;
//         case 'hourly':
//             for (let i = 0; dt <= end; i++) {
//                 arr.push(new Date(dt));
//                 dt.setHours(dt.getHours() + 1);
//             }
//             return arr;
//         default:
//             for (let i = 0; dt <= end; i++) {
//                 arr.push(new Date(dt));
//                 dt.setHours(dt.getHours() + 1);
//             }
//             return arr;
//     }

// }
export function mergeString(strings: string[], separator: string = '•'): any {
  let string1 = '';
  const _sep = separator ? ' ' + separator + ' ' : ' ';
  for (let i = 0; i < strings.length; i++) {
    string1 += strings[i] ? strings[i].trim() : '';
    if (strings[i + 1]) {
      string1 += _sep;
    }
  }
  return string1;
}

export function searchTree(currChild, searchString): void {
  if (currChild.title.includes(searchString)) {
    return currChild;
  } else if (currChild.children != null) {
    for (const i of currChild) {
      if (currChild.children[i].title.includes(searchString)) {
        return currChild.children[i];
      } else {
        searchTree(currChild.children[i], searchString);
      }
    }
    return null;
  }
  return null;
}
/**
 * Returns a given list sorted based on the passed key.
 * @param list the list to sort on.
 * @param pathKey the path where find the key where sort is based on. E.g. 'person.age'
 * @returns the sorted list
 * TODO: rifare senza il getnestedkey
 */
export function sortByKey(list: any, pathKey: string): any {
  return list?.sort((objA, objB) => {
    objA = getNestedKey(objA, pathKey);
    objB = getNestedKey(objB, pathKey);
    // since the value of the key could be an object as
    // additional control i parse it as string from json.
    if (typeof objA !== 'number' && typeof objB !== 'number') {
      objA = JSON.stringify(objA)?.toLowerCase();
      objB = JSON.stringify(objB)?.toLowerCase();
    }
    if (objA < objB) {
      return -1;
    }
    if (objA > objB) {
      return 1;
    }
    return 0;
  });
}
/**
 * Return a nested key from an object.
 * @param obj the obj where dig till the value.
 * @param path path to follow for reach the value.
 * @returns the value of the nested object.
 * @example //How to use it:
 * var persona = {
 *     name: 'Michele',
 *     info: {
 *         eta: 22
 *     }
 * }
 * // returns '22'
 * TODO: valutare di rimuovere
 */
export function getNestedKey(obj: object, path: string): any {
  // looping through the object with the pathKey
  // for reach the nested key in the object.
  for (const keyValue of path.split('.')) {
    obj = ((_obj: any, objKey: string) => _obj[objKey])(obj, keyValue);
  }
  return obj;
}
/**
 * Transform a given object to a string as a valid component of a Uniform Resource Identifier.
 * @param obj the object containing the property to parse as query params.
 * @returns the query params string.
 * TODO: rimuovere in favore di httpParams()  --> https://stackoverflow.com/questions/45470575/angular-4-httpclient-query-parameters
 */
export function queryParamsObjToString(obj: object): string {
  return Array.from(
    // parsing the object into an arrays of his keys
    Object.keys(obj)
  )
    .filter(
      // filtering the array for the prop keys
      (prop) => obj.hasOwnProperty(prop)
    )
    .map(
      // for each key encode it as string and return it
      (param) => `${encodeURI(param)}=${encodeURI(obj[param])}`
    )
    .join('&'); // merge the contents of the array to a string separated from '&'
}
/**
 * Trasform a given querystring to an object.
 * @param querystring the querystring to trasform.
 * @returns the parsed object.
 */
export function queryParamsStringToObj(querystring: string): any {
  // trasform the querystring to an array of entries
  return Array.from(new URLSearchParams(querystring).entries()).reduce((map: any, [key, value]) => {
    // I accumulate all the entries on an object
    map[key] = value;
    return map;
  }, {});
}
/**
 * Given a blob file it will download it.
 * @param blob blob to download.
 * @param fileName name to give at the file to download.
 */
export function downloadBlob(options: { blob: any; fileName: string; fileType: 'pdf' | 'xls' }): void {
  // It is necessary to create a new blob object with mime-type explicitly set
  // otherwise only Chrome works like it should
  const newBlob = new Blob([options?.blob], {
    type: `application/${options?.fileType}`
  });

  // For other browsers:
  // Create a link pointing to the ObjectURL containing the blob.
  const urlData = window.URL.createObjectURL(newBlob);
  const link = document.createElement('a');
  link.href = urlData;
  link.download = `${options?.fileName}.${options?.fileType}`;
  // this is necessary as link.click() does not work on the latest firefox
  link.dispatchEvent(
    new MouseEvent('click', {
      bubbles: true,
      cancelable: true,
      view: window
    })
  );
  setTimeout(() => {
    // For Firefox it is necessary to delay revoking the ObjectURL
    window.URL.revokeObjectURL(urlData);
    link.remove();
  }, 100);
}

export function removeDuplicatesByKey(list: any[], key: string): any[] {
  return _.uniqBy(list, key);
}

/**
 * @param list list that needs to be filtered
 * @param fieldList list of fields to search from (ex. 'name', 'code')
 * @param stringToSearch word to search for
 */
export function search(list: any[], fieldList: string[], stringToSearch: string, removeDuplicatesKey: string = 'id', initalList: any[] = []): any[] {
  const FILTEREDLIST = _.cloneDeep(initalList);
  list.forEach((listValue) => {
    fieldList.forEach((fieldName) => {
      if (listValue[fieldName]?.toString().toLowerCase().includes(stringToSearch?.toString().toLowerCase())) {
        FILTEREDLIST.push(listValue);
      }
    });
  });
  return removeDuplicatesByKey(FILTEREDLIST, removeDuplicatesKey);
}

/**
 * @param list list that needs to be filtered
 * @param fieldList list of fields to search from (ex. 'name', 'code')
 * @param stringToSearch word to search for
 */
export function searchInTree(tree: any[], fieldList: string[], stringToSearch: string): any {
  const NEWLIST: any[] = [];
  tree.forEach((element) => {
    const listObject = [element];
    // check if inside the object there are the "stringToSearch"
    // it's a list with a single object
    // it's list because this line "isChildNodePresent(listValue[fieldList[i]], fieldList, stringToSearch);"
    // re-call the function passing a list, since I can't pass an object at the beginning and after a list
    // i choose to pass directly a list with only 1 obj
    // TODO:this function SHOULD be redone or improved
    const condition = isChildNodePresent(listObject, fieldList, stringToSearch);
    if (condition === true) {
      NEWLIST.push(element);
    }
  });
  return NEWLIST;
}

export function searchInTreeFrontend(searchedText: string, treeSection?: any): void {
  const newTree: any = [];
  let indexfirstTreeElement = 0;
  // check if albero esiste
  if (treeSection) {
    // ciclo su tutti i primi livelli dell albero
    treeSection.forEach((element: any) => {
      // controllo se nei primi livelli il la ricerca è compresa nel nome
      if (element?.name?.toLowerCase().includes(searchedText.toLowerCase())) {
        newTree.push(element);
        // metto il l'elemenyo nel nuovo albero
        if (element['children']) {
          const sonToAddFather: any = []; // array dei figli da aggiungere a padre
          // ciclo i figli del nodo trovato per vedere se contiene qualcosa
          for (const item of element.children) {
            // controllo se nei figli  la ricerca è compresa nel nome
            if (item?.title.toLowerCase().includes(searchedText.toLowerCase())) {
              sonToAddFather.push(item);
            } else {
              // se non è compresa vado a ricercare nei children
              searchInTreeFrontend(searchedText, [item]);
            }
          }
          if (sonToAddFather.length !== 0) {
            newTree[indexfirstTreeElement].children = sonToAddFather;
            indexfirstTreeElement++;
            return newTree;
          } else {
            return newTree;
          }
        } else {
          newTree.push(element);
          return newTree;
        }
        //  return newTree
      } else {
        newTree.push(element);
        // NON CE IL NOME NEL PADRE
        if (element['children']) {
          const sonToAddFather: any = [];
          // ciclo i figli del nodo trovato per vedere se contiene qualcosa
          for (const item of element.children) {
            // controllo se nei figli il la ricerca è compresa nel nome
            if (item?.title?.toLowerCase().includes(searchedText.toLowerCase())) {
              sonToAddFather.push(item);
            } else {
              searchInTreeFrontend(searchedText, [item]);
            }
          }
          if (sonToAddFather.length !== 0) {
            newTree[indexfirstTreeElement].children = sonToAddFather;
            indexfirstTreeElement++;
            return newTree;
          } else {
            return newTree;
          }
        } else {
          newTree.push(element);
          return newTree;
        }
      }
    });
  } else {
    return newTree;
  }
  return newTree;
}

/**
 * @param list list that needs to be filtered
 * @param fieldList list of fields to search from (ex. 'name', 'code')
 * @param stringToSearch word to search for
 */
export function isChildNodePresent(treeLevel: any[], fieldList: string[], stringToSearch: string): boolean {
  let b: boolean = false;
  let i = 0;
  treeLevel.forEach((listValue) => {
    do {
      if (listValue) {
        let condition = listValue[fieldList[i]]?.toString()?.toLowerCase()?.includes(stringToSearch?.toString()?.toLowerCase());
        if (condition) {
          b = true;
          return true;
        }
        condition = typeof listValue[fieldList[i]] === 'object' && listValue[fieldList[i]]?.length > 0;
        if (condition) {
          const B = isChildNodePresent(listValue[fieldList[i]], fieldList, stringToSearch);
          if (B === true) {
            b = true;
            return true;
          }
        }
        i++;
      } else {
        // return false
      }
    } while (i < fieldList?.length);
  });
  return b;
}

/**
 * FUNCTION THAT GENERATE A RANDOM NUMBER
 * @returns A NUMBER
 */
export function generateRandomIntNumber(max: number = 10, min: number = 0): number {
  const randomNuber = Math.floor(Math.random() * (max - min) + min);
  return randomNuber;
}

/**
 * FUNCTION THAT GENERATE A RANDOM COLOR
 * @returns COLOR IN EXADECIMAL NUMBER
 */
export function generateRandomHexColor(): string {
  // reference: https://css-tricks.com/snippets/javascript/random-hex-color/
  const randomColor = Math.floor(Math.random() * 16777215).toString(16);
  return '#' + randomColor;
}

/**
 * function that returns an array containing all dates in a range
 */
export function getAllDateRangeFromStartToEnd(startDate: string, endDate: string): any[] {
  const start = new Date(startDate);
  const end = new Date(endDate);
  const arr: any[] = [];
  const dt = new Date(start);
  while (dt <= end) {
    arr.push(new Date(dt));
    dt.setDate(dt.getDate() + 1);
  }
  return arr;
}

/**
 * FUNCTION THAT TRANSFORM A NUMBER/STRING WITH DOT FOR THOUSANDS AND COMMAS FOR DECIMAL, LIKE ITALIAN FORMAT OF NUMBERS
 * @param value INPUT PARAM WICH CAN BE A STRING OR NUMBER
 * @returns RETURN A STRING THAT CONTAINS THE NUMBER WITH COMMAS FOR DECIMAL AND DOT FOR THOUSANDS
 */
export function formatNumberWithCommaDecimalSeparator(value: string | number): string {
  let decimal = '';
  let numberValue = '';
  value = value.toString();
  value = value.replace('.', ',');
  decimal = value.split(',')[1];
  numberValue = value.split(',')[0];
  value = numberValue.replace(/\B(?=(\d{3})+(?!\d))/g, '.') + ',' + decimal;
  return value;
}

// METODO CHE CALCOLA DIFFERENZA DI GIORNI, ORE E MINUTI TRA DUE DATE, IN QUESTO CASO DATA DI INIZIO E DATA DI FINE
// METHOD THAT CALCULATES DIFFERENCE OF DAYS, HOURS AND MINUTES BETWEEN TWO DATES, IN THIS CASE START DATE AND END DATE
export function getDuration(startDate, endDate): any {
  const m1 = moment(startDate, 'YYYY-MM-DD HH:mm');
  const m2 = moment(endDate, 'YYYY-MM-DD HH:mm');
  const m3 = m2.diff(m1, 'minutes');

  const numdays = Math.floor(m3 / 1440);
  const numhours = Math.floor((m3 % 1440) / 60);
  const numminutes = Math.floor((m3 % 1440) % 60);
  return { days: numdays, hours: numhours, minutes: numminutes };
}

/**
 * FUNCTION THAT TRANSFORM A NUMBER/STRING WITH DOT FOR THOUSANDS AND COMMAS FOR DECIMAL, LIKE ITALIAN FORMAT OF NUMBERS
 * @param value INPUT PARAM WICH CAN BE A STRING OR NUMBER
 * @returns RETURN A STRING THAT CONTAINS THE NUMBER WITH COMMAS FOR DECIMAL AND DOT FOR THOUSANDS
 */
export function formatterData(value: string | number, lang?: string): string {
  if (lang) {
    if (lang == 'it') {
      let decimal;
      let numberValue;
      value = value.toString();
      value = value.replace('.', ',');
      if (value.split(',')[1] != undefined) {
        decimal = value.split(',')[1];
        numberValue = value.split(',')[0];
        value = numberValue.replace(/\B(?=(\d{3})+(?!\d))/g, '.') + ',' + decimal;
      } else {
        numberValue = value.split(',')[0];
        value = numberValue.replace(/\B(?=(\d{3})+(?!\d))/g, '.') + '';
        decimal = '0';
      }
      return value;
    } else if (lang == 'en') {
      return value.toString();
    }
  } else {
    let decimal;
    let numberValue;
    value = value.toString();
    value = value.replace('.', ',');
    if (value.split(',')[1] != undefined) {
      decimal = value.split(',')[1];
      numberValue = value.split(',')[0];
      value = numberValue.replace(/\B(?=(\d{3})+(?!\d))/g, '.') + ',' + decimal;
    } else {
      numberValue = value.split(',')[0];
      value = numberValue.replace(/\B(?=(\d{3})+(?!\d))/g, '.') + '';
      decimal = '0';
    }
    return value;
  }
}

export function getMainImage(data): string {
  if (data.images) {
    for (const image of data.images) {
      if (image.is_main) {
        return image.file;
      }
    }
    return '';
  }
}
