import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie';
import { SettingsConstants, SettingsService, UrlFormatterOptions } from '../../../settings/settings.service';
import { IsApiService } from '../../is-api.service';

@Injectable({
  providedIn: 'root'
})
export class UsersApiService {

  public cookieAge: number;
  public interval: any;

  constructor(private _isApi: IsApiService, private _http: HttpClient, private _settings: SettingsService,
    private _router: Router, private _cookie: CookieService) { }
  public userPreferences;

  async getLoggedUser(callbackFn: (error: Error, data: any) => void): Promise<any> {
    try {
      const user = await this._http.get<any>(`${SettingsConstants.BACKENDURL}${this._settings.getApi('getLoggedUser')}`).toPromise();
      if (user) {
        SettingsConstants.LOGGEDUSER = user;
      }
      callbackFn(null, user);
    } catch (error) {
      callbackFn(error, null);
    }
  }

  public getUsers(callbackFn: (error: Error, data: any) => void, queryParams = ''): void {
    const request = this._isApi.request({
      api: 'getUsers',
      method: 'get',
      queryParams
    });
    this._isApi.subscribe(request, callbackFn);
  }

  public setUserPreferences(callbackFn: (error: Error, data: any) => void, bodyRequest: {}): void {
    // if (SettingsConstants.LOGGEDUSER?.id) {
    const request = this._isApi.request({
      api: 'setUserPreferences',
      method: 'post',
      bodyRequest,
      params: {
        id: SettingsConstants.LOGGEDUSER?.id
      }
    });
    this._isApi.subscribe(request, (err: Error, data: any) => {
      callbackFn(err, data);

      // updating the user preferences
      this.getUserPreferences();
    });
  }

  public async getUserPreferences(): Promise<any> {
    const optionsForCall: UrlFormatterOptions = {
      url: this._settings.getApi('getUserPreferences'),
      params: {
        id: SettingsConstants.LOGGEDUSER?.id
      }
    };
    const preferences = await this._http.get<any>(this._settings.formatUrl(optionsForCall)).toPromise();
    SettingsConstants.USERPREFERENCES = preferences;
  }

  public async getUserPermissions(): Promise<any> {
    const optionsForCall: UrlFormatterOptions = {
      url: this._settings.getApi('getUserPermissions'),
      params: {
        id: SettingsConstants.LOGGEDUSER?.id
      }
    };
    const permissions = await this._http.get<any>(this._settings.formatUrl(optionsForCall)).toPromise();
    permissions.forEach((permission) => {
      SettingsConstants.USERPERMISSIONS[permission] = true;
    });
  }

  public async getUserCustomizations(): Promise<any> {
    const optionsForCall: UrlFormatterOptions = {
      url: this._settings.getApi('getUserCustomizations')
    };
    const customizations = await this._http.get<any>(this._settings.formatUrl(optionsForCall)).toPromise();
    SettingsConstants.USERCUSTOMIZATIONS = customizations;
  }

  public getPersons(callbackFn: (error: Error, data: any) => void, queryParams: string = ''): void {
    const request = this._isApi.request({
      api: 'getPersons',
      method: 'get',
      queryParams
    });
    this._isApi.subscribe(request, callbackFn);
  }

}
