import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie';
import { SettingsConstants } from 'projects/is-core/src/lib/settings/settings.service';

@Injectable({
  providedIn: 'root'
})
export class IsLoginService {

  public redirectUrl: string;

  constructor(
    private _httpClient: HttpClient,
    private _cookie: CookieService,
    private _router: Router
  ) { }

  public setCookie(cookieName: string, cookieValue: string): void {
    this._cookie.put(cookieName, cookieValue, { path: '/' });
  }

  public setToken(token: string): void {
    const expirationDate = new Date();
    expirationDate.setDate(expirationDate.getDate() + 14);
    this._cookie.put('token', token, { path: '/', expires: expirationDate });
  }

  public getToken(): string {
    return this._cookie.get('token');
  }

  public logout(): void {
    const URL = `${SettingsConstants.BACKENDURL}/rest-auth/logout/`;
    this._httpClient.post(URL, {}).subscribe({
      next: (data: any) => {
        this._cookie.removeAll();
        // if login url is present has key in settings.json redirect to specified url - else redirect to default '/login' path
        if (SettingsConstants.LOGINURL) {
          location.href = `${SettingsConstants.BACKENDURL}${SettingsConstants.LOGINURL}`;
        } else {
          this._router.navigate(['/', 'login']);
        }
      },
      error: (err: Error) => {
        console.error(err);
      },
      complete: () => { }
    });
  }

  public isLoggedIn(): boolean {
    const usertoken = this.getToken();
    if (usertoken != null) {
      return true;
    }
    return false;
  }
}
