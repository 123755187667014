import { Component, Input } from '@angular/core';
import { Toast, ToastrService, ToastPackage } from 'ngx-toastr';
import { ACTION_TOAST_ANIMATIONS } from './action-toast.animations';

@Component({
  selector: '[action-toast-component]',
  templateUrl: './action-toast.component.html',
  styleUrls: ['./action-toast.component.scss'],
  animations: ACTION_TOAST_ANIMATIONS,
  preserveWhitespaces: false
})
export class ActionToastComponent extends Toast {
  @Input() buttonText: string = null;

  constructor(
    protected toastrService: ToastrService,
    public toastPackage: ToastPackage
  ) {
    super(toastrService, toastPackage);
  }

  action(event: Event): boolean {
    event.stopPropagation();
    this.toastPackage.triggerAction();
    this.toastrService.clear();
    return false;
  }
}

// showCustomToast(options: {
//   type: 'error' | 'success' | 'warning' | 'info';
//   buttonText: string;
// }): ActiveToast<any> {
//   const activeToast = this._toastr[options.type]('Messaggio', 'Titolo', {
//     ...toastrConfig,
//     ...{
//       closeButton: true,
//       toastComponent: ActionToastComponent,
//     },
//   });
//   activeToast.toastRef.componentInstance.buttonText = options.buttonText;
//   return activeToast;
// }
